<template>
  <v-container fluid grid-list-md v-if="boardItem">
    <AppTitleBar>
      <template v-slot:title>{{ boardItem.entity.display }}</template>
      <template slot="actions">
        <v-tooltip top transition="fade-transition">
          <template v-slot:activator="{ on }">
            <v-btn icon small @click="dismiss" v-if="board" v-on="on">
              <v-icon small>{{ $vuetify.icons.values.viewBack }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('labels.backTo') }} {{ board ? board.display : '' }}</span>
        </v-tooltip>
        <v-menu bottom left min-width="150" v-if="!readOnly">
          <template #activator="{ on: menu }">
            <v-tooltip top transition="fade-transition">
              <template #activator="{ on: tooltip }">
                <v-btn icon small slot="activator">
                  <v-icon small v-text="$vuetify.icons.values.options" v-on="{ ...tooltip, ...menu }" />
                </v-btn>
              </template>
              <span>{{ $t('labels.actions') }}</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item @click="archive" v-if="$entity.isAllowed('destroy', boardItem.entity)">
              <v-list-item-title>{{ $t('labels.archive') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="destroy" v-if="$entity.isAllowed('destroy', boardItem.entity)">
              <v-list-item-title>{{ $t('labels.delete') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="duplicate(true)" v-if="$deal.isAllowed('create')">
              <v-list-item-title>{{ $t('labels.duplicate') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="duplicate(false)" v-if="$deal.isAllowed('create')">
              <v-list-item-title>{{ $t('labels.duplicateAsIndividual') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </AppTitleBar>

    <v-row column>
      <v-col cols="12">
        <VWidget :enable-toolbar="false">
          <template v-slot:content>
            <StateStepper
              :board-item="boardItem"
              :include="include"
              :key="`stepper-${boardItem.entity.refid}`"
              :read-only="readOnly"
              use-i18n
            />
          </template>
        </VWidget>
      </v-col>
      <v-col cols="12">
        <v-tabs v-model="activeTab" slider-color="grey darken-1">
          <v-tab ripple>{{ $t('labels.overview') }}</v-tab>
          <v-tab-item class="mt-4">
            <GroupDealOverview
              :deal="boardItem.entity"
              :key="`group-overview-${boardItem.entity.refid}`"
              :read-only="readOnly"
            />
          </v-tab-item>

          <v-tab ripple>{{ $t('labels.deal') }}</v-tab>
          <v-tab-item class="mt-4">
            <GroupDealGeneral
              :deal="boardItem.entity"
              :key="`group-general-${boardItem.entity.refid}`"
              :read-only="readOnly"
            />
          </v-tab-item>

          <v-tab ripple>{{ $t('labels.insurance') }}</v-tab>
          <v-tab-item class="mt-4">
            <EntityInsurance
              :deal-id="boardItem.entity.id"
              :deal-refid="boardItem.entity.refid"
              :key="`EntityInsurance-${boardItem.entity.refid}`"
              :read-only="readOnly"
            />
          </v-tab-item>

          <v-tab ripple>{{ $t('labels.clients') }}</v-tab>
          <v-tab-item class="mt-4">
            <GroupApplicants
              :deal-id="boardItem.entity.id"
              :key="`GroupApplicants-${boardItem.entity.refid}`"
              :read-only="readOnly"
            />
          </v-tab-item>

          <v-tab ripple>{{ $t('labels.documents') }}</v-tab>
          <v-tab-item class="mt-4">
            <v-container fluid grid-list-xs class="pa-0 mt-2">
              <v-row>
                <v-col cols="12" v-if="$deal.isAllowed('atLeastAdvisor')">
                  <ComplianceFiles
                    :boardItem="boardItem"
                    :key="`ComplianceFiles-${boardItem.entity.refid}`"
                    :read-only="readOnly"
                  />
                </v-col>
                <v-col cols="12">
                  <Documents
                    :boardItem="boardItem"
                    :enable-private="$deal.isAllowed('atLeastAdvisor')"
                    :key="`files-${boardItem.entity.refid}`"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>

          <v-tab ripple>{{ $t('labels.notes') }}</v-tab>
          <v-tab-item class="mt-4">
            <Notes
              :boardItem="boardItem"
              :enable-private="$deal.isAllowed('atLeastAdvisor')"
              :key="`comments-${boardItem.entity.refid}`"
            />
          </v-tab-item>

          <v-tab ripple v-if="$deal.isAllowed('atLeastAdvisor')">{{ $t('labels.tasks') }}</v-tab>
          <v-tab-item class="mt-4" v-if="$deal.isAllowed('atLeastAdvisor')">
            <Tasks
              :boardItem="boardItem"
              :enable-owner="$deal.isAllowed('atLeastManager')"
              :enable-private="$deal.isAllowed('atLeastAdvisor')"
              :key="`tasks-${boardItem.entity.refid}`"
              :read-only="readOnly"
            />
          </v-tab-item>

          <v-tab ripple v-if="$deal.isAllowed('atLeastAdvisor')">{{ $t('labels.logs') }}</v-tab>
          <v-tab-item class="mt-4" v-if="$deal.isAllowed('atLeastAdvisor')">
            <v-tabs v-model="activeLogTab" mandatory>
              <v-tab ripple>{{ $t('labels.deal') }}</v-tab>
              <v-tab-item class="mt-4">
                <VDealLogbook :entity-id="boardItem.entity.id" :key="`deal-logbook-${boardItem.entity.refid}`" />
              </v-tab-item>

              <v-tab ripple>{{ $t('labels.products') }}</v-tab>
              <v-tab-item class="mt-4">
                <VProductLogbook :entity-id="boardItem.entity.id" :key="`product-logbook-${boardItem.entity.refid}`" />
              </v-tab-item>

              <v-tab ripple>{{ $t('labels.tasks') }}</v-tab>
              <v-tab-item class="mt-4">
                <VTaskLogbook :entity-id="boardItem.entity.id" :key="`task-logbook-${boardItem.entity.refid}`" />
              </v-tab-item>

              <v-tab ripple>{{ $t('labels.notes') }}</v-tab>
              <v-tab-item class="mt-4">
                <VNoteLogbook :entity-id="boardItem.entity.id" :key="`note-logbook-${boardItem.entity.refid}`" />
              </v-tab-item>

              <v-tab ripple>{{ $t('labels.documents') }}</v-tab>
              <v-tab-item class="mt-4">
                <VDocumentLogbook
                  :entity-id="boardItem.entity.id"
                  :key="`document-logbook-${boardItem.entity.refid}`"
                />
              </v-tab-item>
              <v-tab ripple>{{ $t('labels.compliance') }}</v-tab>
              <v-tab-item class="mt-4">
                <VComplianceLogbook
                  :entity-id="boardItem.entity.id"
                  :key="`compliance-logbook-${boardItem.entity.refid}`"
                />
              </v-tab-item>
            </v-tabs>
          </v-tab-item>

          <v-tab ripple v-if="$deal.isAllowed('atLeastAdvisor')">
            {{ $t('labels.transitions') }}
            <span v-if="hasTransitionWarning">
              <v-icon small class="ml-2" v-if="hasTransitionWarning">{{ $vuetify.icons.values.warning }}</v-icon>
            </span>
          </v-tab>

          <v-tab-item class="mt-4" v-if="$deal.isAllowed('atLeastAdvisor')">
            <VDealTransitions :key="`transitions-${boardItem.entity.refid}`" />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <modal-loading :visible="overlay" />
    <router-view></router-view>
  </v-container>
  <v-loading v-else />
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { AppTitleBar, VWidget, VLoading } from '@argon/app/components'
import { NAMESPACE as BOARD_NAMESPACE } from '@argon/evo/boards/store'
import { StateStepper } from '@argon/evo/entities/components'
import entityPerimeter from '@argon/evo/entities/perimeters'
import { CardMixin } from '@argon/evo/entities/mixins'
import { Modal } from '@argon/app/mixins'
import { ModalLoading } from '../../components'
import { EntityInsurance } from '../../insurance/components'
import { VDealLogbook } from '../../logbook/deals/components'
import { VProductLogbook } from '../../logbook/products/components'
import { VTaskLogbook } from '../../logbook/tasks/components'
import { VDocumentLogbook } from '../../logbook/documents/components'
import { VNoteLogbook } from '../../logbook/notes/components'
import { VComplianceLogbook } from '../../logbook/compliance/components'
import { NAMESPACE as DEAL_NAMESPACE } from '../store'
import dealPerimeter from '../perimeters'
import {
  GroupDealOverview,
  GroupDealGeneral,
  GroupApplicants,
  ComplianceFiles,
  VDealTransitions,
  Notes,
  Tasks,
  Documents
} from '../components'

export default {
  name: 'GroupDealPageWidget',
  components: {
    GroupDealOverview,
    GroupDealGeneral,
    GroupApplicants,
    VDealLogbook,
    VProductLogbook,
    VTaskLogbook,
    VDocumentLogbook,
    VNoteLogbook,
    VComplianceLogbook,
    ComplianceFiles,
    EntityInsurance,
    VDealTransitions,
    Tasks,
    Notes,
    Documents,
    VWidget,
    AppTitleBar,
    StateStepper,
    VLoading,
    ModalLoading
  },
  perimeters: [entityPerimeter, dealPerimeter],
  mixins: [CardMixin, Modal],
  props: {
    entityId: { type: [Number, String], required: true },
    boardRef: { type: String, required: true }
  },
  data: () => ({
    overlay: false,
    activeTab: null,
    activeLogTab: null,
    include: 'lead,clients,broker,firm,property,mortgage,advisor',
    returnPath: { name: 'tableView' }
  }),
  watch: {
    entityId: function () {
      this.fetchData().finally(() => {
        this.overlay = false
      })
    }
  },
  computed: {
    ...mapState(BOARD_NAMESPACE, ['board']),
    readOnly() {
      return !this.$deal.isAllowed('atLeastAdvisor')
    }
  },
  methods: {
    ...mapActions(BOARD_NAMESPACE, ['getBoard']),
    ...mapActions(DEAL_NAMESPACE, ['clearDealClients', 'clearComplianceFiles', 'duplicateDeal']),
    duplicate(keepType) {
      this.overlay = true
      this.duplicateDeal({ dealId: this.boardItem.entity.id, individual: !keepType, full: false }).then((deal) => {
        this.$router.push({
          name: 'boardCard',
          params: { boardRef: deal.deal_type, entityId: deal.id }
        })
      })
    }
  },
  destroyed() {
    Promise.all([this.clearDealClients(), this.clearComplianceFiles()])
  }
}
</script>

<style scoped>
.tools__area {
  min-height: 200px;
}
</style>
