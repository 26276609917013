<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.taskLogbook') }}</template>
    <template v-slot:content>
      <v-data-table :items="logs" hide-default-headers>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ $t(`labels.${item.action}`) }}</td>
            <td>{{ item.data.display }}</td>
            <td>{{ item.data.actor_display }}</td>
            <td class="text-right grey--text">{{ transformDate(item.created_on) }}</td>
          </tr>
        </template>
      </v-data-table>
    </template>
  </VWidget>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { NAMESPACE } from '../store'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { formatDT } from '@argon/app/helpers'
import { VWidget } from '@argon/app/components'

export default {
  name: 'VTaskLogbook',
  components: { VWidget },
  props: {
    entityId: { type: [Number, String], required: true }
  },
  computed: {
    ...mapState(NAMESPACE, ['logs']),
    ...mapGetters(ME_NAMESPACE, ['timezone', 'locale'])
  },
  methods: {
    ...mapActions(NAMESPACE, ['listLogs']),
    transformDate(value) {
      return value ? formatDT(value, { format: 'LL', tz: this.timezone, locale: this.locale }) : ''
    }
  },
  created() {
    this.listLogs(this.entityId)
  }
}
</script>

<style></style>
