<template>
  <v-container fluid class="stretch pb-0">
    <v-loading v-if="loading" />
    <div class="stretch growing__column" v-else>
      <AppTitleBar>
        <template v-slot:title>{{ $t(`${title}`) }}</template>
        <template slot="actions">
          <v-tooltip top transition="fade-transition">
            <template v-slot:activator="{ on }">
              <v-btn icon small @click="create" v-on="on" v-if="canCreate">
                <v-icon small v-text="$vuetify.icons.values.add" />
              </v-btn>
            </template>
            <span>{{ $t(`labels.new${capitalizedBoardEntityType}`) }}</span>
          </v-tooltip>

          <span v-if="switchViews && displayType !== 'kanban'">
            <v-tooltip top transition="fade-transition">
              <template v-slot:activator="{ on }">
                <v-btn icon small @click="goToKanban" v-on="on">
                  <v-icon small v-text="$vuetify.icons.values.columnsView" />
                </v-btn>
              </template>
              <span>{{ $t('switchView') }}</span>
            </v-tooltip>
          </span>

          <span v-if="switchViews && displayType !== 'table'">
            <v-tooltip top transition="fade-transition">
              <template v-slot:activator="{ on }">
                <v-btn icon small @click="goToTable" v-on="on">
                  <v-icon small v-text="$vuetify.icons.values.tableView" />
                </v-btn>
              </template>
              <span>{{ $t('switchView') }}</span>
            </v-tooltip>
          </span>

          <v-menu bottom left min-width="150" v-if="$board.isAllowed('manage', board)">
            <template #activator="{ on: menu }">
              <v-tooltip top transition="fade-transition">
                <template #activator="{ on: tooltip }">
                  <v-btn icon small slot="activator">
                    <v-icon small v-text="$vuetify.icons.values.options" v-on="{ ...tooltip, ...menu }" />
                  </v-btn>
                </template>
                <span>{{ $t('labels.actions') }}</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item v-if="$board.isAllowed('manage', board)" @click="showUsers">
                <v-list-item-title>{{ $t('labels.users') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="archive" v-if="$board.isAllowed('destroy', board)">
                <v-list-item-title>{{ $t('labels.archive') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="destroy" v-if="$board.isAllowed('destroy', board)">
                <v-list-item-title>{{ $t('labels.delete') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="$board.isAllowed('manage', board)" @click="showRestore">
                <v-list-item-title>{{ $t('labels.restore') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </AppTitleBar>

      <slot>
        <router-view></router-view>
      </slot>
    </div>
  </v-container>
</template>

<script>
import { capitalize } from 'lodash/string'

import { mapState, mapActions } from 'vuex'
import { create } from 'vue-modal-dialogs'
import { AppTitleBar, VLoading } from '@argon/app/components'
import { NAMESPACE as REALTIME_NAMESPACE } from '@argon/realtime/store'
import { ConfirmDeleteBoardDialog, ConfirmArchiveBoardDialog } from '@argon/evo/boards/components'
import { NAMESPACE as ENT_NAMESPACE, KANBAN, TABLE } from '@argon/evo/entities/store'
import { NAMESPACE } from '@argon/evo/boards/store'
import boardPerimeter from '@argon/evo/boards/perimeters'

const confirmDeleteBoardAction = create(ConfirmDeleteBoardDialog)
const confirmArchiveBoardAction = create(ConfirmArchiveBoardDialog)

export default {
  name: 'DealBoardWidget',
  components: {
    AppTitleBar,
    VLoading
  },
  data: () => ({
    loading: true,
    defaultView: 'table',
    propertiesLink: 'dealBoardProperties',
    usersLink: 'dealBoardUsers'
  }),
  props: {
    orgRef: String,
    boardRef: {
      type: [String, Number],
      required: true
    },

    provided: Array,
    nestModals: { type: Boolean, default: false },
    autoLoad: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    switchViews: { type: Boolean, default: false },
    kanbanLink: { type: String, default: 'kanbanView' },
    tableLink: { type: String, default: 'tableView' },
    boardListLink: { type: String, default: 'boards' },
    boardLink: { type: String, default: 'board' },
    newEntityLink: { type: String, default: 'newEntity' },
    restoreLink: { type: String, default: 'entityRestore' }
  },
  perimeters: [boardPerimeter],
  computed: {
    ...mapState(NAMESPACE, ['board', 'title']),
    ...mapState(ENT_NAMESPACE, ['displayType']),
    display() {
      return this.board && this.board.meta.settings ? this.board.meta.settings.view : this.defaultView
    },
    computedBoardLink() {
      return this.displayType ? `${this.displayType}${this.boardLink}` : this.boardLink
    },
    canCreate() {
      return !this.readOnly && this.$board.isAllowed('update', this.board)
    },
    capitalizedBoardEntityType() {
      return this.board ? capitalize(this.board.entity_type) : ''
    },
    realtimeKey() {
      return this.board
        ? this.$store.getters[`${REALTIME_NAMESPACE}/realtimeKey`]({
            category: `${this.board.entity_type}Boards`,
            identifier: this.board.refname
          })
        : null
    }
  },
  methods: {
    ...mapActions(REALTIME_NAMESPACE, ['addListener', 'removeListener']),
    ...mapActions(NAMESPACE, ['addPrincipals', 'getBoard', 'archiveBoards', 'deleteBoard', 'clearBoard']),
    computeRoute(link) {
      return {
        name: this.nestModals && this.displayType ? `${this.displayType}${link}` : link,
        params: {
          ...this.$router.params,
          boardLink: this.computedBoardLink
        }
      }
    },
    goToList() {
      this.$router.push({ name: this.boardListLink })
    },
    goToTable() {
      if (this.$route.name === this.tableLink) return
      this.$router.push({ name: this.tableLink, params: { ...this.$router.params } })
    },
    goToKanban() {
      if (this.$route.name === this.kanbanLink) return
      this.$router.push({ name: this.kanbanLink, params: { ...this.$router.params } })
    },
    loadView() {
      if (!this.autoLoad) {
        return
      }
      switch (this.display) {
        case KANBAN:
          return this.goToKanban()
          break
        case TABLE:
          return this.goToTable()
          break
        default:
          break
      }
    },
    showProperties() {
      let route = this.computeRoute(this.propertiesLink)
      this.$router.push(route)
    },
    showUsers() {
      let route = this.computeRoute(this.usersLink)
      this.$router.push(route)
    },
    showRestore() {
      let route = this.computeRoute(this.restoreLink)
      this.$router.push(route)
    },
    archive() {
      confirmArchiveBoardAction().then((res) => {
        if (res) {
          this.archiveBoards([this.board.uris.self]).then(() => {
            this.goToList()
          })
        }
      })
    },
    destroy() {
      confirmDeleteBoardAction().then((res) => {
        if (res) {
          this.deleteBoard(this.boardRef).then(() => {
            this.goToList()
          })
        }
      })
    },
    create() {
      let route = this.computeRoute(this.newEntityLink)
      this.$router.push(route)
    },
    addUsersToBoard(payload) {
      return this.addPrincipals({ ...payload, boardRef: this.boardRef })
    },
    fetchData() {
      return this.getBoard(this.boardRef)
        .then(() => {
          this.loadView()
          this.loading = false
        })
        .catch((error) => {
          if (error.status_code === 404) {
            this.$router.push({ name: 404 })
          }
        })
    }
  },
  watch: {
    realtimeKey: function (v, oldV) {
      if (oldV) {
        this.fetchData()
      }
    }
  },
  created() {
    this.fetchData().then(() => {
      if (this.board) {
        this.addListener({
          category: `${this.board.entity_type}Boards`,
          identifier: this.board.refname
        })
      }
    })
  },
  destroyed() {
    if (this.board) {
      this.removeListener({ category: `${this.board.entity_type}Boards`, identifier: this.board.refname })
    }
    this.clearBoard()
  }
}
</script>

<style scoped>
.stretch {
  height: 100%;
}
.growing__column {
  display: flex;
  flex-flow: column;
}
.flow-toolbar {
  margin-bottom: 1em;
}
</style>

<i18n>
{
  "en": {
    "Home": "Home",
    "Life Individual": "Life Individual",
    "Life Group": "Life Group"
  },
  "fr": {
    "Home": "Habitation",
    "Life Individual": "Vie individuel",
    "Life Group": "Vie collectif"
  }
}
</i18n>
