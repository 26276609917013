<template>
  <VModalWidget :dismiss="dismiss" :loading="loading" no-esc disable-actions large content-class="pa-0">
    <template v-slot:title>{{ $t('labels.logbookEntry') }}</template>

    <template v-slot:content v-if="logEntry">
      <v-list dense>
        <v-list-item>
          <v-list-item-subtitle>{{ $t(`labels.${logEntry.action}`) }}</v-list-item-subtitle>
          <v-list-item-title class="text-right">{{ $t(`labels.${logEntry.category}`) }}</v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-subtitle>{{ $t('labels.actor') }}</v-list-item-subtitle>
          <v-list-item-title class="text-right">
            {{ logEntry.data.actor_display || $t('labels.system') }}
          </v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-subtitle>{{ $t('labels.updatedOn') }}</v-list-item-subtitle>
          <v-list-item-title class="text-right">{{ transformDate(logEntry.created_on) }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <template v-for="category in ['changes', 'removals']">
        <v-divider :key="`sep-${category}-${logEntry.id}`" />
        <h3 class="pa-4" :key="`log-${category}-${logEntry.id}`">{{ $t(`labels.${category}`) }}</h3>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="logEntry.data.diff[category]"
          :key="`log-table-${category}-${logEntry.id}`"
        >
          <template v-slot:[`item.label`]="{ item }">
            {{ $t(`labels.${item.label}`) }}
          </template>
          <template v-slot:[`item.previous_value`]="{ item }">
            {{ $t(`labels.${item.previous_value}`) }}
          </template>
          <template v-slot:[`item.value`]="{ item }">
            {{ $t(`labels.${item.value}`) }}
          </template>
        </v-data-table>
      </template>
    </template>
  </VModalWidget>
</template>

<script>
import { isArray } from 'util'
import { mapActions, mapState, mapGetters } from 'vuex'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { VModalWidget } from '@argon/app/components'
import { formatDT } from '@argon/app/helpers'
import { Modal } from '@argon/app/mixins'
import { NAMESPACE } from '../store'

export default {
  name: 'ModalLogBookEntry',
  mixins: [Modal],
  components: {
    VModalWidget
  },
  props: {
    entityId: { type: [Number, String], required: true },
    logId: { type: [Number, String], required: true }
  },
  data() {
    return {
      logEntry: null,
      prevRoute: null,
      loading: true,
      headers: [
        {
          text: this.$t('labels.displayName'),
          value: 'label',
          sortable: false
        },
        {
          text: this.$t('labels.previous_value'),
          value: 'previous_value',
          sortable: false,
          align: 'right'
        },
        {
          text: this.$t('labels.currentValue'),
          value: 'value',
          sortable: false,
          align: 'right'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(ME_NAMESPACE, ['timezone', 'locale'])
  },
  methods: {
    ...mapActions(NAMESPACE, ['getLogbookEntry']),
    transformDate(value) {
      return value ? formatDT(value, { format: 'LL', tz: this.timezone, locale: this.locale }) : ''
    }
  },
  created() {
    this.getLogbookEntry({ entityId: this.entityId, logId: this.logId }).then((logEntry) => {
      this.logEntry = logEntry
      this.loading = false
    })
  }
}
</script>
