<template>
  <DealBoardWidget :key="`${boardRef}-board`" :orgRef="orgRef" :boardRef="boardRef" nest-modals auto-load read-only />
</template>

<script>
import { DealBoardWidget } from '../widgets'

export default {
  name: 'BoardView',
  components: { DealBoardWidget },
  props: {
    orgRef: String,
    boardRef: {
      type: String,
      required: true
    }
  }
}
</script>
