var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SearchBar',{attrs:{"context":_vm.context,"search-fields":_vm.searchFields,"filterItems":_vm.filterItems}}),_c('FormErrors',{attrs:{"show":_vm.showResourceErrors,"errors":_vm.resourceErrors}}),_c('v-card',{staticClass:"main-card",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"py-1 px-0"},[_c('v-data-table',{staticClass:"main-table elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"server-items-length":_vm.tableItemsCount,"page":_vm.page,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"items-per-page":_vm.itemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.itemsPerPageArray },"must-sort":"","show-expand":"","single-expand":"","item-key":"entity.id"},on:{"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"item.step",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("labels." + (item.stateObj ? item.stateObj.display : ''))))+" ")]}},{key:"item.report",fn:function(ref){
var item = ref.item;
return [_c('entity-status',{attrs:{"report":item.report}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("labels." + (item.entity.status))))+" ")]}},{key:"item.refnum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.entity.refnum)+" "),(item.readOnly)?_c('v-chip',{attrs:{"color":"warning","label":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.readOnly))])],1):_vm._e()]}},{key:"item.clients",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.entity.client_names ? item.entity.client_names.join(', ') : '')+" ")]}},{key:"item.created_on",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.transformDate(item.entity.created_on))+" ")]}},{key:"item.updated_on",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.transformDate(item.entity.updated_on))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.$deal.isAllowed('atLeastAdvisor'))?_c('EntityStateMenu',{attrs:{"item":item,"states":_vm.columns,"request-transition":_vm.doSetToState}}):_vm._e(),_c('v-btn',{attrs:{"small":"","icon":"","to":_vm.getBoardItemRoute(item.entity)}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.view))])],1)]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":_vm.headers.length}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-list',{staticClass:"pt-0",attrs:{"dense":""}},[_c('v-list-item',{staticClass:"py-1"},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t('labels.firm')))]),_c('v-list-item-title',[_vm._v(_vm._s(item.entity.firm ? item.entity.firm.display : '-'))])],1)],1),_c('v-divider'),_c('v-list-item',{staticClass:"py-1"},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t('labels.broker')))]),_c('v-list-item-title',[_vm._v(_vm._s(item.entity.broker_name))])],1)],1),_c('v-divider'),_c('v-list-item',{staticClass:"py-1"},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t('labels.advisor')))]),_c('v-list-item-title',[_vm._v(_vm._s(item.entity.advisor_name || '-'))])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-list',{staticClass:"pt-0",attrs:{"dense":""}},[_c('v-list-item',{staticClass:"py-1"},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t('labels.blocker')))]),_c('v-list-item-title',[_vm._v(_vm._s(item.entity.blocker.display || '-'))])],1)],1),_c('v-divider'),(_vm.$deal.isAllowed('atLeastAdvisor'))?_c('v-list-item',{staticClass:"py-1"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('labels.compliance')))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t(("labels." + (item.entity.compliance))))+" ")])],1),_c('v-list-item-action',[_c('compliance-icon',{attrs:{"deal":item.entity,"small":""}})],1)],1):_vm._e(),_c('v-divider')],1)],1)],1)],1)]}}],null,true)})],1)],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }