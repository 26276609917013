<template>
  <div>
    <SearchBar :context="context" :search-fields="searchFields" :filterItems="filterItems" advanced />
    <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
    <v-card flat class="main-card">
      <v-card-text class="py-1 px-0">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :server-items-length="tableItemsCount"
          :page.sync="page"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items-per-page.sync="itemsPerPage"
          :footer-props="{ itemsPerPageOptions: itemsPerPageArray }"
          item-key="entity.id"
          class="main-table elevation-0"
        >
          <template v-slot:[`item.step`]="{ item }">
            {{ $t(`labels.${item.stateObj ? item.stateObj.display : ''}`) }}
          </template>
          <template v-slot:[`item.report`]="{ item }">
            <entity-status :report="item.report" />
          </template>

          <template v-slot:[`item.status`]="{ item }">
            {{ $t(`labels.${item.entity.status}`) }}
          </template>

          <template v-slot:[`item.refnum`]="{ item }">
            {{ item.entity.refnum }}
            <v-chip color="warning" label v-if="item.readOnly">
              <v-icon small>{{ $vuetify.icons.values.readOnly }}</v-icon>
            </v-chip>
          </template>

          <template v-slot:[`item.clients`]="{ item }">
            {{ item.entity.client_names ? item.entity.client_names.join(', ') : '' }}
          </template>

          <template v-slot:[`item.created_on`]="{ item }">
            {{ transformDate(item.entity.created_on) }}
          </template>

          <template v-slot:[`item.updated_on`]="{ item }">
            {{ transformDate(item.entity.updated_on) }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <EntityStateMenu :item="item" :states="columns" :request-transition="doSetToState" />
            <v-btn small icon :to="getBoardItemRoute(item.entity)">
              <v-icon small>{{ $vuetify.icons.values.view }}</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatDT } from '@argon/app/helpers'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { EntityStateMenu, EntityStatus, SearchBar } from '@argon/evo/entities/components'
import entityPerimeter from '@argon/evo/entities/perimeters'
import { FormErrors } from '@argon/app/components'
import { EntityDatatableMixin } from '@argon/evo/entities/mixins'
import dealPerimeter from '../perimeters'

export default {
  name: 'HomeDealTableWidget',
  perimeters: [entityPerimeter, dealPerimeter],
  components: {
    FormErrors,
    SearchBar,
    EntityStatus,
    EntityStateMenu
  },
  mixins: [EntityDatatableMixin],
  data: function () {
    return {
      expandable: true,
      headers: [
        { text: '', sortable: false, value: 'report', align: 'left', width: 20 },
        {
          text: this.$t('labels.status'),
          value: 'status',
          sortable: false,
          width: 100
        },
        {
          text: this.$t('labels.step'),
          value: 'step',
          sortable: false,
          width: 100
        },
        {
          text: this.$t('labels.deal_id'),
          value: 'refnum',
          width: 160
        },
        {
          text: this.$t('labels.provider_lead_id'),
          value: 'entity.provider_lead_id'
        },
        { text: this.$t('labels.clients'), value: 'clients', sortable: false },
        { text: this.$t('labels.createdOn'), value: 'created_on', sortable: true, width: 115 },
        { text: this.$t('labels.updatedOn'), value: 'updated_on', sortable: true, width: 115 },
        { text: '', value: 'actions', sortable: false, align: 'right', width: 100 }
      ],
      searchFields: [
        { label: 'display', type: 'text' },
        {
          label: 'step',
          type: 'list',
          values: ['initial', 'sent_to_fortus', 'sent_to_crm', 'completed']
        },
        { label: 'refnum', type: 'text' },
        { label: 'broker_name', type: 'text' },
        { label: 'client_names', type: 'text' },
        { label: 'provider_lead_id', type: 'text' },
        { label: 'source_lead_id', type: 'text' }
      ],
      include: null,
      sortBy: 'updated_on',
      sortDesc: true,
      counts: null
    }
  },
  computed: {
    ...mapGetters(ME_NAMESPACE, ['timezone'])
  },
  methods: {
    transformDate(value) {
      return value ? formatDT(value, { format: 'DD/MM/YYYY', tz: this.timezone }) : ''
    }
  },
  created() {
    if (!this.$deal.isAllowed('atLeastManager')) {
      this.$router.push({ name: 'me' })
    }
  }
}
</script>
